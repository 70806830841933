import axios from "axios";
import * as rax from "retry-axios";
import { batch } from "react-redux";

import {
  FETCH_PAGES,
  UPDATE_PAGE,
  RESTORE_PAGES,
  CHANGE_IMAGE,
  CHANGE_VIDEO,
  CHANGE_AUDIO,
  MOVE_AUDIO,
  ADD_AUDIO,
  DELETE_AUDIO,
  CHANGE_CARD_IMAGE,
  CHANGE_CARD_AUDIO,
  ADD_CARD,
  DELETE_CARD,
  OPEN_EDITOR,
  REMOVE_IMAGE,
  REMOVE_VIDEO,
  REMOVE_AUDIO,
  REMOVE_CARD_IMAGE,
  REMOVE_CARD_AUDIO,
  CHANGE_TEXT,
  CHANGE_REMARK_TEXT,
  CHANGE_CARD_TEXT,
  CHANGE_CARD_REMARK_TEXT,
  CHANGE_TITLE,
  CHANGE_TEACHING_NOTES,
  CHANGE_REMARK_TEACHING_NOTES,
  UPDATE_SECTION,
  SET_ERROR,
  UPDATE_UNIT,
  CLOSE_EDITOR,
  ADD_IMAGE,
  ADD_VIDEO,
  CHANGE_VIDEO_STATUS,
  ADD_FILE,
  UPDATE_RESOURCE,
  LOADING_PAGES,
  MOVE_CARD,
  UPDATE_TRAINING,
  FETCH_PAGES_NUMBER,
  UPDATE_FOLDER,
  UPDATE_VIDEOLIST,
  SET_LOAD,
  CLOSE_LOAD,
  ADD_PLAYGROUND,
  DELETE_PLAYGROUND,
  ADD_PLAYGROUNDLEVEL
} from "./type";

import mediaServerAddress from "../config/mediaServerAddress";
import { rollbar } from "../loggers";

// media server for video

// fetch all the pages in section
export const fetchPages = (options, callback) => async (dispatch, getStore) => {
  dispatch({ type: LOADING_PAGES, payload: {} });

  let query = ``;

  if (options.pageType !== undefined)
    query = `${query}&pageType=${options.pageType}`;
  if (options.sectionId !== undefined)
    query = `${query}&sectionId=${options.sectionId}`;
  if (options.unitId !== undefined) query = `${query}&unitId=${options.unitId}`;
  if (options.bookId !== undefined) query = `${query}&bookId=${options.bookId}`;
  if (options.bookTypeId !== undefined)
    query = `${query}&bookTypeId=${options.bookTypeId}`;
  if (options.booksetId !== undefined)
    query = `${query}&booksetId=${options.booksetId}`;
  if (options.resourceId !== undefined)
    query = `${query}&resourceId=${options.resourceId}`;
  if (options.isHidden !== undefined)
    query = `${query}&isHidden=${options.isHidden.toString()}`;
  if (options.isTrash !== undefined)
    query = `${query}&isTrash=${options.isTrash.toString()}`;

  if (options.folderId !== undefined)
    query = `${query}&folderId=${options.folderId}`;

  if (options.videoFolderId !== undefined)
    query = `${query}&videoFolderId=${options.videoFolderId}`;

  if (options.videoListId !== undefined)
    query = `${query}&videoListId=${options.videoListId}`;

  const route = `page${query ? `?${query.slice(1)}` : ``}`;

  const {
    data: { pageMap }
  } = await axios.get(route);

  dispatch({
    type: FETCH_PAGES,
    payload: { pageMap }
  });

  if (callback) callback();
};

// create one page
export const createPage =
  (formProps, callback) => async (dispatch, getStore) => {
    const {
      data: { page }
    } = await axios.post(`page`, formProps);

    let res;
    switch (formProps.pageType) {
      case `teachingAssistant`:
        res = await axios.patch(`section/${formProps.sectionId}`, {
          patch: {
            action: `ADD_TEACHING_ASSISTANT`,
            data: { pageId: page._id, position: formProps.position }
          }
        });

        batch(() => {
          dispatch({ type: UPDATE_PAGE, payload: { page } });

          dispatch({
            type: UPDATE_SECTION,
            payload: { section: res.data?.section }
          });
        });
        break;

      case `lessonPlan`:
        res = await axios.patch(`unit/${formProps.unitId}`, {
          patch: {
            action: `ADD_LESSON_PLAN`,
            data: { pageId: page._id }
          }
        });

        batch(() => {
          dispatch({ type: UPDATE_PAGE, payload: { page } });

          dispatch({
            type: UPDATE_UNIT,
            payload: { unit: res.data?.unit }
          });
        });
        break;

      case `teachingVideo`:
        res = await axios.patch(`unit/${formProps.unitId}`, {
          patch: {
            action: `ADD_TEACHING_VIDEO`,
            data: {
              pageId: page._id,
              teachingVideoId: formProps.teachingVideoId
            }
          }
        });

        batch(() => {
          dispatch({ type: UPDATE_PAGE, payload: { page } });

          dispatch({
            type: UPDATE_UNIT,
            payload: { unit: res.data?.unit }
          });
        });
        break;

      case `downloadPage`:
        const {
          data: { folder }
        } = await axios.patch(`category/folder/${formProps.folderId}`, {
          patch: {
            action: `addDownloadPage`,
            data: {
              categoryId: formProps.categoryId,
              downloadPageId: page._id
            }
          }
        });

        batch(() => {
          dispatch({ type: UPDATE_PAGE, payload: { page } });

          dispatch({
            type: UPDATE_FOLDER,
            payload: { folder }
          });
        });
        break;

      case `videosPage`:
        const {
          data: { videoList }
        } = await axios.patch(`category/folder/${formProps.folderId}`, {
          patch: {
            action: `addVideosPage`,
            data: {
              categoryId: formProps.categoryId,
              folderId: formProps.folderId,
              downloadPageId: page._id
            }
          }
        });

        batch(() => {
          dispatch({ type: UPDATE_PAGE, payload: { page } });

          dispatch({
            type: UPDATE_VIDEOLIST,
            payload: { videoList }
          });
        });
        break;

      case `trainingVideo`:
        res = await axios.patch(`training/${formProps.trainingId}`, {
          patch: {
            action: `ADD_TRAINING_VIDEO`,
            data: {
              pageId: page._id
            }
          }
        });

        batch(() => {
          dispatch({ type: UPDATE_PAGE, payload: { page } });

          dispatch({
            type: UPDATE_TRAINING,
            payload: { training: res.data?.training }
          });
        });
        break;

      default:
        await axios.delete(`page/${page._id}`);
        return dispatch({
          type: SET_ERROR,
          payload: `Unprocessable page type`
        });
    }

    if (callback) callback(page);
  };

// save page
export const savePage =
  (formProps, callback, existFileSrcs, existImageSrcs) =>
  async (dispatch, getStore) => {
    const pageMap = getStore().page.pageMap;
    const editor = getStore().page.editor;

    const newPage = {};

    newPage.title = formProps.title;
    newPage.isHidden = formProps.isHidden;
    newPage.isAutoPlayMedia = formProps.isAutoPlayMedia;
    newPage.data = {};
    newPage.data.teachingNotes = formProps.teachingNotes;
    newPage.data.remarkTeachingNotes = formProps.remarkTeachingNotes;

    newPage.data._files = editor.data._files;

    newPage.data.playgroundLevel = editor.data.playgroundLevel;

    newPage.data.playgrounds = editor.data.playgrounds;

    // walk through the text fields in editor and load the value from form
    newPage.data.texts =
      editor.data.texts &&
      editor.data.texts.map((text, index) => {
        return { ...text, value: formProps[`text_${index}`] };
      });

    // walk through the remarks fields in editor and load the value from form
    newPage.data.remarks =
      editor.data.remarks &&
      editor.data.remarks.map((remark, index) => {
        return { ...remark, value: formProps[`remark_${index}`] };
      });

    // walk through the answers of the page
    newPage.data.answers = [];
    Object.keys(formProps).forEach((key) => {
      if (key.indexOf(`answer_`) !== -1) {
        if (formProps[key] !== undefined) {
          newPage.data.answers[key.split("_")[1]] =
            formProps[key] === true
              ? 1
              : formProps[key] === false
              ? 0
              : parseInt(formProps[key], 10);
        }
      }
    });

    const uselessImages = {};
    const uselessVideos = {};
    const uselessAudios = {};

    // walk through the origin page and store the image data
    pageMap[editor._id].data.images &&
      pageMap[editor._id].data.images.forEach((image) => {
        if (image?._image?._id) uselessImages[image._image._id] = true;
      });

    // walk through the origin page and store the video data
    pageMap[editor._id].data.videos &&
      pageMap[editor._id].data.videos.forEach((video) => {
        if (video?._video?._id) uselessVideos[video._video._id] = true;
      });

    // walk through the origin page and store the audio data
    pageMap[editor._id].data.audios &&
      pageMap[editor._id].data.audios.forEach((audio) => {
        if (audio?._audio?._id) uselessAudios[audio._audio._id] = true;
      });

    // walk through the origin page and store the card data
    pageMap[editor._id].data.cards &&
      pageMap[editor._id].data.cards.forEach((card) => {
        if (card) {
          if (card.image?._image?._id)
            uselessImages[card.image._image._id] = true;
          if (card.audio?._audio?._id)
            uselessAudios[card.audio._audio._id] = true;
          if (card.audios) {
            card.audios.forEach((audio) => {
              if (audio?._audio?._id) uselessAudios[audio._audio._id] = true;
            });
          }
          if (card.images) {
            card.images.forEach((image) => {
              if (image?._image?._id) uselessImages[image._image._id] = true;
            });
          }
        }
      });

    newPage.data.images =
      editor.data.images &&
      (await Promise.all(
        // walk through the image fields in editor and load the value from form
        editor.data.images.map(async (element, index) => {
          if (element === null) return null;

          const { _image } = element;

          // image still in use, remove form useless list
          if (_image?._id && uselessImages[_image._id])
            delete uselessImages[_image._id];

          // if image is uploaded
          if (formProps[`image_${index}`]) {
            // if old image exist, delete old image, mark useless
            if (_image?._id) uselessImages[_image._id] = true;

            const fd = new FormData();

            fd.append("image", formProps[`image_${index}`]);

            // upload new image
            const {
              data: { image }
            } = await mediaServerAddress.post(`image`, fd);

            return { ...element, _image: image };
          }

          return element;
        })
      ));

    // clean up deleted images
    newPage.data.images =
      newPage.data.images && newPage.data.images.filter((element) => element);

    // ================================================================

    newPage.data.videos =
      editor.data.videos &&
      (await Promise.all(
        // walk through the video fields in editor and load the value from form
        editor.data.videos.map(async (element, index) => {
          if (element === null) return null;
          const { _video } = element;

          // video still in use, remove form useless list
          if (_video?._id && uselessVideos[_video._id]) {
            delete uselessVideos[_video._id];
          }

          // if video is uploaded
          if (formProps[`video_${index}`]) {
            // if old video exist, delete old video, mark useless
            if (_video?._id) uselessVideos[_video._id] = true;

            const fd = new FormData();

            fd.append("video", formProps[`video_${index}`]);

            // upload new video

            const {
              data: { video }
            } = await mediaServerAddress.post(`video`, fd);

            return { ...element, _video: video };
          }

          return element;
        })
      ));

    // clean up deleted videos
    newPage.data.videos =
      newPage.data.videos && newPage.data.videos.filter((element) => element);
    // ===================================================================

    newPage.data.audios =
      editor.data.audios &&
      (await Promise.all(
        // walk through the audio fields in editor and load the value from form
        editor.data.audios.map(async (element, index) => {
          if (element === null) return null;

          const { _audio } = element;

          // audio still in use, remove form useless list
          if (_audio?._id && uselessAudios[_audio._id])
            delete uselessAudios[_audio._id];

          // if audio is uploaded
          if (formProps[`audio_${index}`]) {
            // if old audio exist, delete old audio, mark useless
            if (_audio?._id) uselessAudios[_audio._id] = true;

            const configs = {
              headers: {
                [`content-type`]: `multipart/form-data`
              }
            };

            const fd = new FormData();

            fd.append("audio", formProps[`audio_${index}`]);

            // upload new audio
            const {
              data: { audio }
            } = await axios.post(`audio`, fd, configs);

            return { ...element, _audio: audio };
          }

          return element;
        })
      ));

    // clean up deleted audio
    newPage.data.audios =
      newPage.data.audios && newPage.data.audios.filter((element) => element);

    newPage.data.cards =
      editor.data.cards &&
      (await Promise.all(
        // walk through the card fields in editor and load the value from form
        editor.data.cards.map(async (card, index) => {
          if (card === null) return null;

          const newCard = { ...card };

          // images still in use, remove from useless list
          if (card.images) {
            card.images.forEach((image) => {
              if (image?._image?._id && uselessImages[image._image._id])
                delete uselessImages[image._image._id];
            });

            // walk through the images of the card
            for (const key in formProps) {
              if (key.indexOf(`cardImage_${index}_`) !== -1) {
                if (formProps[key]) {
                  const subIndex = key.split("_")[2];
                  // if old image exist, delete old image, mark useless
                  if (card.images[subIndex]?._image?._id)
                    uselessImages[card.images[subIndex]._image._id] = true;

                  const fd = new FormData();

                  fd.append(
                    "image",
                    formProps[`cardImage_${index}_${subIndex}`]
                  );

                  // upload new image

                  const {
                    data: { image }
                  } = await mediaServerAddress.post(`image`, fd);

                  newCard.images[subIndex] = {
                    ...card.images[subIndex],
                    _image: image
                  };
                }
              }
            }
          }

          // image still in use, remove form useless list
          if (card.image?._image?._id && uselessImages[card.image._image._id])
            delete uselessImages[card.image._image._id];

          // if image is uploaded
          if (formProps[`cardImage_${index}`]) {
            // if old image exist, delete old image, mark useless
            if (card.image?._image?._id)
              uselessImages[card.image._image._id] = true;

            const fd = new FormData();

            fd.append("image", formProps[`cardImage_${index}`]);

            // upload new image

            const {
              data: { image }
            } = await mediaServerAddress.post(`image`, fd);
            newCard.image = { ...card.image, _image: image };
          }

          if (card.audios) {
            // audios still in use, remove from useless list
            card.audios.forEach((audio) => {
              if (audio?._audio?._id && uselessAudios[audio._audio._id])
                delete uselessAudios[audio._audio._id];
            });
          }

          // walk through the audios of the card
          for (const key in formProps) {
            if (key.indexOf(`cardAudio_${index}_`) !== -1) {
              if (formProps[key]) {
                const subIndex = key.split("_")[2];
                // if old audio exist, delete old audio, mark useless
                if (card.audios[subIndex]?._audio?._id)
                  uselessAudios[card.audios[subIndex]._audio._id] = true;

                const configs = {
                  headers: {
                    [`content-type`]: `multipart/formdata`,
                    apptype: "ta"
                  }
                };

                const fd = new FormData();

                fd.append("audio", formProps[`cardAudio_${index}_${subIndex}`]);

                // upload new audio
                const {
                  data: { audio }
                } = await axios.post(`audio`, fd, configs);

                newCard.audios[subIndex] = {
                  ...card.audios[subIndex],
                  _audio: audio
                };
              }
            }
          }

          // audio still in use, remove form useless list
          if (card.audio?._audio?._id && uselessAudios[card.audio._audio._id])
            delete uselessAudios[card.audio._audio._id];

          // if audio is uploaded
          if (formProps[`cardAudio_${index}`]) {
            // if old audio exist, delete old audio, mark useless
            if (card.audio?._audio?._id)
              uselessAudios[card.audio._audio._id] = true;

            const configs = {
              headers: {
                [`content-type`]: `multipart/form-data`
              }
            };

            const fd = new FormData();

            fd.append("audio", formProps[`cardAudio_${index}`]);

            // upload new audio
            const {
              data: { audio }
            } = await axios.post(`audio`, fd, configs);

            newCard.audio = { ...card.audio, _audio: audio };
          }

          if (card.texts) {
            card.texts.forEach((text, subIndex) => {
              if (text) {
                newCard.texts[subIndex] = {
                  ...text,
                  value: formProps[`cardText_${index}_${subIndex}`]
                };
              }
            });
          }

          // card remarks
          if (card.remarks) {
            card.remarks.forEach((remark, subIndex) => {
              if (remark) {
                newCard.remarks[subIndex] = {
                  ...remark,
                  value: formProps[`cardRemark_${index}_${subIndex}`]
                };
              }
            });
          }

          if (card.text) {
            newCard.text = {
              ...card.text,
              value: formProps[`cardText_${index}`]
            };
          }

          // card remark
          if (card.remark) {
            newCard.remark = {
              ...card.remark,
              value: formProps[`cardRemark_${index}`]
            };
          }

          newCard.title = formProps[`cardTitle_${index}`];

          return newCard;
        })
      ));

    // clean up deleted cards
    newPage.data.cards =
      newPage.data.cards &&
      newPage.data.cards.filter((element) => {
        return element !== null;
      });

    // clean up old images
    await axios.delete(`image`, {
      data: { imageIds: Object.keys(uselessImages) }
    });

    // clean up old video
    await axios.delete(`video`, {
      data: { videoIds: Object.keys(uselessVideos) }
    });

    // clean up old audios
    await axios.delete(`audio`, {
      data: { audioIds: Object.keys(uselessAudios) }
    });

    const {
      data: { page }
    } = await axios.patch(`page/${editor._id}`, {
      patch: { action: `UPDATE`, data: newPage, existFileSrcs, existImageSrcs }
    });

    const updatedPage = {
      ...pageMap[editor._id],
      ...newPage
    };

    updatedPage.data._files = page.data._files; // update download page._files

    dispatch({ type: UPDATE_PAGE, payload: { page: updatedPage } });

    if (callback) callback();
  };

// remove the page
export const removePage =
  ({ pageType, pageId, sectionId }, callback) =>
  async (dispatch, getStore) => {
    const pageMap = getStore().page.pageMap;

    let res;
    let pageRes;
    switch (pageType) {
      case `teachingAssistant`:
        res = await axios.patch(`section/${sectionId}`, {
          patch: {
            action: `REMOVE_TEACHING_ASSISTANT`,
            data: { pageId }
          }
        });

        if (pageMap[pageId] && pageMap[pageId]._section === sectionId) {
          pageRes = await axios.patch(`page/${pageId}`, {
            patch: { action: `REMOVE` }
          });

          batch(() => {
            dispatch({
              type: UPDATE_SECTION,
              payload: { section: res.data?.section }
            });

            dispatch({
              type: UPDATE_PAGE,
              payload: { page: pageRes.data.page }
            });
          });
        } else {
          dispatch({
            type: UPDATE_SECTION,
            payload: { section: res.data?.section }
          });
        }

        break;

      default:
        return dispatch({
          type: SET_ERROR,
          payload: `Unprocessable Page Type`
        });
    }

    if (callback) callback();
  };

// restore the page
export const restorePage =
  ({ pageType, pageId, sectionId }, callback) =>
  async (dispatch, getStore) => {
    const {
      data: { page }
    } = await axios.patch(`page/${pageId}`, {
      patch: { action: `RESTORE` }
    });

    let res;
    switch (pageType) {
      case `teachingAssistant`:
        res = await axios.patch(`section/${sectionId}`, {
          patch: {
            action: `RESTORE_TEACHING_ASSISTANT`,
            data: { pageId }
          }
        });

        batch(() => {
          dispatch({
            type: UPDATE_SECTION,
            payload: { section: res.data?.section }
          });

          dispatch({ type: UPDATE_PAGE, payload: { page } });
        });
        break;

      default:
        return dispatch({
          type: SET_ERROR,
          payload: `Unprocessable Page Type`
        });
    }

    if (callback) callback();
  };

export const restorePages =
  ({ pageType, pageIds, sectionId }, callback) =>
  async (dispatch, getStore) => {
    await axios.patch(`page`, {
      patch: { action: `RESTORE` },
      pageIds
    });

    let res;
    switch (pageType) {
      case `teachingAssistant`:
        res = await axios.patch(`section/${sectionId}`, {
          patch: {
            action: `RESTORE_TEACHING_ASSISTANT`,
            data: { pageIds }
          }
        });

        batch(() => {
          dispatch({
            type: UPDATE_SECTION,
            payload: { section: res.data?.section }
          });

          dispatch({
            type: RESTORE_PAGES,
            payload: { pageIds }
          });
        });
        break;

      default:
        return dispatch({
          type: SET_ERROR,
          payload: `Unprocessable Page Type`
        });
    }

    if (callback) callback();
  };

// delete the page
export const deletePage =
  (
    {
      pageType,
      pageId,
      sectionId,
      unitId,
      teachingVideoId,
      resourceId,
      trainingId
    },
    callback
  ) =>
  async (dispatch, getStore) => {
    let res;
    switch (pageType) {
      case `teachingAssistant`:
        res = await axios.patch(`section/${sectionId}`, {
          patch: {
            action: `DELETE_TEACHING_ASSISTANT`,
            data: { pageId }
          }
        });

        dispatch({
          type: UPDATE_SECTION,
          payload: { section: res.data?.section }
        });
        break;

      case 1:
      case `lessonPlan`:
        res = await axios.patch(`unit/${unitId}`, {
          patch: {
            action: `DELETE_LESSON_PLAN`,
            data: { pageId }
          }
        });

        dispatch({
          type: UPDATE_UNIT,
          payload: { unit: res.data?.unit }
        });
        break;

      case 2:
      case `teachingVideo`:
        res = await axios.patch(`unit/${unitId}`, {
          patch: {
            action: `DELETE_TEACHING_VIDEO`,
            data: { pageId, teachingVideoId }
          }
        });

        dispatch({
          type: UPDATE_UNIT,
          payload: { unit: res.data?.unit }
        });

        break;

      case `resource`:
        res = await axios.patch(`resource/${resourceId}`, {
          patch: {
            action: `DELETE_PAGE`,
            data: { pageId }
          }
        });

        dispatch({
          type: UPDATE_RESOURCE,
          payload: { resource: res.data?.resource }
        });

        break;

      case `trainingVideo`:
        res = await axios.patch(`training/${trainingId}`, {
          patch: {
            action: `DELETE_TRAINING_VIDEO`,
            data: { pageId }
          }
        });

        dispatch({
          type: UPDATE_TRAINING,
          payload: { training: res.data?.training }
        });

        break;

      default:
        return dispatch({
          type: SET_ERROR,
          payload: `Unprocessable Page Type`
        });
    }

    if (callback) callback();
  };

// delete the page
export const deletePages =
  ({ pageType, pageIds, sectionId }, callback) =>
  async (dispatch, getStore) => {
    let res;
    switch (pageType) {
      case `teachingAssistant`:
        res = await axios.patch(`section/${sectionId}`, {
          patch: {
            action: `DELETE_TEACHING_ASSISTANT`,
            data: { pageIds }
          }
        });

        dispatch({
          type: UPDATE_SECTION,
          payload: { section: res.data?.section }
        });
        break;

      default:
        return dispatch({
          type: SET_ERROR,
          payload: `Unprocessable Page Type`
        });
    }

    callback();
  };

// move the page
export const movePage =
  ({ pageType, pageId, sectionId }, to, callback) =>
  async (dispatch, getStore) => {
    let res;
    switch (pageType) {
      case `teachingAssistant`:
        res = await axios.patch(`section/${sectionId}`, {
          patch: {
            action: `MOVE_TEACHING_ASSISTANT`,
            data: { pageId, to }
          }
        });

        dispatch({
          type: UPDATE_SECTION,
          payload: { section: res.data?.section }
        });
        break;

      default:
        return dispatch({
          type: SET_ERROR,
          payload: `Unprocessable Page Type`
        });
    }

    if (callback) callback();
  };

// change the title
export const changeTitle = (value) => ({
  type: CHANGE_TITLE,
  payload: { value }
});

// change the teaching notes
export const changeTeachingNotes = (value) => ({
  type: CHANGE_TEACHING_NOTES,
  payload: { value }
});

// change the remark teaching notes
export const changeRemarkTeachingNotes = (value) => ({
  type: CHANGE_REMARK_TEACHING_NOTES,
  payload: { value }
});

// change the text
export const changeText = (index, value) => ({
  type: CHANGE_TEXT,
  payload: { index, value }
});

// change the remark
export const changeRemarkText = (index, value) => ({
  type: CHANGE_REMARK_TEXT,
  payload: { index, value }
});

// change the card text
export const changeCardText = (index, value, subIndex) => ({
  type: CHANGE_CARD_TEXT,
  payload: { index, value, subIndex }
});

// change the remark card text
export const changeRemarkCardText = (index, value, subIndex) => ({
  type: CHANGE_CARD_REMARK_TEXT,
  payload: { index, value, subIndex }
});

/**
 * IMAGE
 */

// change the image src
export const changeImage = (index, name, src) => ({
  type: CHANGE_IMAGE,
  payload: { index, name, src }
});

// remove the image src
export const removeImage = (index) => ({
  type: REMOVE_IMAGE,
  payload: { index }
});

/**
 * VIDEO
 */

// change the Video src
export const changeVideo = (index, name, src) => ({
  type: CHANGE_VIDEO,
  payload: { index, name, src }
});

// remove the video src
export const removeVideo = (index) => ({
  type: REMOVE_VIDEO,
  payload: { index }
});

//change the video status
export const changeVideoStatus = (index, pageId, video) => ({
  type: CHANGE_VIDEO_STATUS,
  payload: { index, pageId, video }
});

/**
 * AUDIO
 */

// change the audio src
export const changeAudio = (index, name, src) => ({
  type: CHANGE_AUDIO,
  payload: { index, name, src }
});

// remove the audio src
export const removeAudio = (index) => ({
  type: REMOVE_AUDIO,
  payload: { index }
});

/**
 * AUDIO FOR TEMPLATE STYLE 1.1 ONLY
 */

// move audio
export const moveAudio = (index, position) => {
  return {
    type: MOVE_AUDIO,
    payload: { index, position }
  };
};

// add audio
export const addAudio = (audio) => {
  return { type: ADD_AUDIO, payload: { audio } };
};

// delete audio
export const deleteAudio = (index) => {
  return { type: DELETE_AUDIO, payload: { index } };
};

/**
 * CARD
 */

// change the image src
export const changeCardImage = (index, name, src, subIndex) => {
  return {
    type: CHANGE_CARD_IMAGE,
    payload: { index, name, src, subIndex }
  };
};

export const removeCardImage = (index, subIndex) => {
  return {
    type: REMOVE_CARD_IMAGE,
    payload: { index, subIndex }
  };
};

//change the audio src
export const changeCardAudio = (index, name, src, subIndex) => {
  return {
    type: CHANGE_CARD_AUDIO,
    payload: { index, subIndex, name, src }
  };
};

export const removeCardAudio = (index, subIndex) => {
  return {
    type: REMOVE_CARD_AUDIO,
    payload: { index, subIndex }
  };
};

// add card
export const addCard = (card) => {
  return { type: ADD_CARD, payload: { card } };
};

// delete card
export const deleteCard = (index) => {
  return { type: DELETE_CARD, payload: { index } };
};

//move card
export const moveCard = (index, position) => {
  return {
    type: MOVE_CARD,
    payload: { index, position }
  };
};

/**
 * PAGE
 */

export const openEditor = (pageId) => ({
  type: OPEN_EDITOR,
  payload: { pageId }
});

export const closeEditor = () => ({ type: CLOSE_EDITOR, payload: {} });

export const addTeachingVideoList =
  (unitId, callback) => async (dispatch, getStore) => {
    const {
      data: { unit }
    } = await axios.patch(`unit/${unitId}`, {
      patch: { action: `ADD_TEACHING_VIDEO_LIST` }
    });

    dispatch({
      type: UPDATE_UNIT,
      payload: { unit }
    });

    if (callback) callback();
  };

export const deleteTeachingVideoList =
  (unitId, teachingVideoId, callback) => async (dispatch, getStore) => {
    const {
      data: { unit }
    } = await axios.patch(`unit/${unitId}`, {
      patch: {
        action: `DELETE_TEACHING_VIDEO_LIST`,
        data: { teachingVideoId }
      }
    });

    dispatch({
      type: UPDATE_UNIT,
      payload: { unit }
    });

    if (callback) callback();
  };

// upload image (for download page)
export const uploadImage =
  (pageId, file, callback) => async (dispatch, getStore) => {
    const fd = new FormData();

    fd.append("image", file);

    // upload new image

    const {
      data: { image }
    } = await mediaServerAddress.post(`image`, fd);

    const {
      data: { page }
    } = await axios.patch(`page/${pageId}`, {
      patch: { action: `UPLOAD_IMAGE`, data: { imageId: image._id } }
    });

    dispatch({
      type: ADD_IMAGE,
      payload: { image: { _image: image } }
    });

    if (callback) callback(image.src);
  };

// ===================================================================

// upload video
export const uploadVideo =
  (pageId, file, callback) => async (dispatch, getStore) => {
    const fd = new FormData();

    fd.append("video", file);

    // upload new video

    const {
      data: { video }
    } = await mediaServerAddress.post(`video`, fd);

    const {
      data: { page }
    } = await axios.patch(`page/${pageId}`, {
      patch: { action: `UPLOAD_VIDEO`, data: { videoId: video._id } }
    });

    dispatch({
      type: UPDATE_PAGE,
      payload: { page }
    });

    dispatch({
      type: ADD_VIDEO,
      payload: { video: video }
    });

    if (callback) callback(video.src);
  };

// ===================================================================

// upload file
export const uploadFile =
  (pageId, newfile, callback) => async (dispatch, getStore) => {
    const fd = new FormData();

    fd.append("file", newfile);

    // upload new file
    const {
      data: { file }
    } = await axios.post(`file`, fd, {
      headers: {
        [`content-type`]: `multipart/form-data`
      }
    });

    const {
      data: { page }
    } = await axios.patch(`page/${pageId}`, {
      patch: { action: `UPLOAD_FILE`, data: { fileId: file._id } }
    });

    dispatch({
      type: ADD_FILE,
      payload: { file: file._id }
    });

    if (callback) callback(file.src, file.name);
  };

export const uploadHTML5Zip = async ({ file, userId, change, dispatch }) => {
  dispatch({ type: SET_LOAD, payload: "Progress: init..." });
  const filename = file.name;
  const filetype = file.type;
  const baseURL = process.env.REACT_APP_HTML5EXPORT;
  const userURL = baseURL + userId + "/";
  const signurl = await axios.post(`signurl`, {
    destination: baseURL.match(/\w*\/$/i)[0] + userId + "/" + filename,
    filetype: filetype
  });
  dispatch({ type: SET_LOAD, payload: "Progress: uploading..." });
  const folderName = filename.replace(/.zip$/, "");
  const defaultPattern = userURL + folderName + "/index.html";
  const FolderNamePattern = userURL + folderName + "/" + folderName + ".html";
  const res = await axios.put(signurl.data, file, {
    headers: {
      "Content-Type": filetype
    }
  });

  dispatch({ type: SET_LOAD, payload: "Progress: unzipping..." });
  const validator = axios.create();
  let isResolve = false;
  const maxRetry = 25;
  const toPercent = 100 / maxRetry;
  validator.defaults.raxConfig = {
    instance: validator,
    retry: maxRetry,
    noResponseRetries: 5,
    retryDelay: 10000,
    statusCodesToRetry: [
      [100, 199],
      [400, 429],
      [500, 599]
    ],
    backoffType: "linear",
    shouldRetry: (err) => {
      if (isResolve) {
        return false;
      }
      return rax.shouldRetryRequest(err); // built-in logic
    },
    onRetryAttempt: (err) => {
      const cfg = rax.getConfig(err);
      console.log(`Retry attempt #${cfg.currentRetryAttempt}`);
      dispatch({
        type: SET_LOAD,
        payload: `Progress: unzipping... (${
          cfg.currentRetryAttempt * toPercent
        } / 100)`
      });
    }
  };
  const interceptorId = rax.attach(validator);
  const promises = [
    validator.get(defaultPattern),
    validator.get(FolderNamePattern)
  ];

  let error = false;
  await Promise.any(promises)
    .then((values) => {
      isResolve = true;
      let htmlURL = values.config.url;
      change("text_0", htmlURL); // for save
      dispatch({
        type: CHANGE_TEXT,
        payload: { index: 0, value: htmlURL }
      }); // for display
    })
    .catch((err) => {
      rollbar.error(err);
      error = true;
    });

  dispatch({ type: CLOSE_LOAD });
  rax.detach(interceptorId);
  return error;
};

// upload thumbnail
export const uploadThumbnail =
  (pageId, thumbnail, callback) => async (dispatch, getStore) => {
    const fd = new FormData();

    fd.append("image", thumbnail);

    // upload thumbnail
    const {
      data: { image }
    } = await mediaServerAddress.post(`image`, fd);

    const {
      data: { page }
    } = await axios.patch(`page/${pageId}`, {
      patch: { action: `UPLOAD_THUMBNAIL`, data: { imageId: image._id } }
    });

    dispatch({
      type: UPDATE_PAGE,
      payload: { page }
    });

    if (callback) {
      callback();
    }
  };

// fetch number of all the visible pages
export const fetchPagesNumber =
  (options, callback) => async (dispatch, getStore) => {
    const configs = {
      headers: { ClientType: "TA" }
    };

    let query = ``;

    if (options.pageType !== undefined)
      query = `${query}&pageType=${options.pageType}`;
    if (options.sectionId !== undefined)
      query = `${query}&sectionId=${options.sectionId}`;
    if (options.unitId !== undefined)
      query = `${query}&unitId=${options.unitId}`;
    if (options.bookId !== undefined)
      query = `${query}&bookId=${options.bookId}`;
    if (options.bookTypeId !== undefined)
      query = `${query}&bookTypeId=${options.bookTypeId}`;
    if (options.booksetId !== undefined)
      query = `${query}&booksetId=${options.booksetId}`;
    if (options.resourceId !== undefined)
      query = `${query}&resourceId=${options.resourceId}`;
    if (options.isHidden !== undefined)
      query = `${query}&isHidden=${options.isHidden.toString()}`;
    if (options.isTrash !== undefined)
      query = `${query}&isTrash=${options.isTrash.toString()}`;

    const route = `pageNumber${query ? `?${query.slice(1)}` : ``}`;

    const {
      data: { pagesNumbers }
    } = await axios.get(route, configs);

    dispatch({
      type: FETCH_PAGES_NUMBER,
      payload: pagesNumbers
    });

    if (callback) callback();
  };

// check video status
export const checkVideoStatus = (videoId) => async (dispatch, getStore) => {
  const {
    data: { status, _thumbnail }
  } = await axios.get(`video/checkStatus/${videoId}`);

  return { status, _thumbnail };
};

/**
 * PLAYGROUND
 */

//add playground
export const addPlayground = (name) => {
  return { type: ADD_PLAYGROUND, payload: name };
};

//delete playground
export const deletePlayground = (index) => {
  return { type: DELETE_PLAYGROUND, payload: { index } };
};

export const addPlaygroundLevel = (level) => {
  return { type: ADD_PLAYGROUNDLEVEL, payload: level };
};
