import styled from "styled-components/macro";

export default styled.div`
  max-width: 500px;
  max-height: 250px;

  @media (min-width: 740px) and (min-height: 440px) {
    max-width: 690px;
    max-height: 345px;
  }

  @media (min-width: 992px) and (min-height: 595px) {
    max-width: 930px;
    max-height: 465px;
  }

  @media (min-width: 1200px) and (min-height: 710px) {
    max-width: 1110px;
    max-height: 555px;
  }

  @media (min-width: 1400px) and (min-height: 830px) {
    max-width: 1290px;
    max-height: 645px;
  }

  @media (min-width: 1590px) and (min-height: 940px) {
    max-width: 1470px;
    max-height: 735px;
  }

  @media (min-width: 1840px) and (min-height: 1080px) {
    max-width: 1690px;
    max-height: 845px;
  }

  @media (min-width: 2000px) and (min-height: 1220px) {
    max-width: 1910px;
    max-height: 955px;
  }

  margin-left: auto;
  margin-right: auto;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  position: relative;
`;
