import axios from "axios";

import {
  UPDATE_STUDENTANSWER,
  FETCH_STUDENTANSWER,
  FETCH_CLASSROOMSTUDENTANSWER
} from "./type";

// 處理音檔上傳的輔助函數
const uploadAudioFile = async (audio) => {
  if (!audio?.file) return audio; // 如果音檔不存在，直接返回原資料

  const configs = {
    headers: {
      "content-type": "multipart/form-data"
    }
  };

  const fd = new FormData();
  fd.append("audio", audio.file, "audio/mp3"); // 把音檔加入表單
  fd.append("name", audio.name); // 將音檔名稱加入表單

  try {
    // 上傳音檔到 S3 並獲取返回的音檔資料
    const {
      data: { audio: uploadedAudio }
    } = await axios.post("student/audio", fd, configs);

    return {
      ...audio, // 保留原有資料
      _audio: uploadedAudio // 更新音檔資訊
    };
  } catch (error) {
    console.error("音檔上傳失敗", error);
    return audio; // 出錯時返回原音檔
  }
};

// 處理頁面的輔助函數
const processPage = async (page) => {
  if (page.answers?.audios) {
    const updatedAudios = await Promise.all(
      page.answers.audios.map(uploadAudioFile)
    );

    // 返回更新後的頁面
    return {
      ...page,
      answers: {
        ...page.answers,
        audios: updatedAudios
      }
    };
  }

  // 如果該頁面不是要處理的頁面，直接返回原頁面
  return page;
};

// 主 Action 函數
export const updateStudentAnswer =
  (fromProps, callback) => async (dispatch, getStore) => {
    const { studentAnswerInit } = fromProps;

    const userId = studentAnswerInit._user;
    const unitId = studentAnswerInit._unit;

    const sectionId = studentAnswerInit.sections[0]._section;

    let query = `?userId=${userId}`;

    let currentStudentAnswer;

    try {
      const response = await axios.get(`studentAnswer/${unitId}${query}`); //查找是否有這個使用者的unit

      // 如果 response.data 存在，從中解構 currentStudentAnswer，否則設置為 null
      currentStudentAnswer = response.data.studentAnswer || null;

      if (currentStudentAnswer) {
        const matchedSection = currentStudentAnswer.sections.find(
          (section) => section._section === sectionId
        );

        if (matchedSection) {
          // 過濾並刪除音檔
          await Promise.all(
            matchedSection.data.map(async (page) => {
              if (page.answers?.audios) {
                // 將每個音檔的 _id 發送到後端進行刪除
                await Promise.all(
                  page.answers.audios.map(async (audio) => {
                    if (audio && audio._audio) {
                      try {
                        await axios.delete(`student/audio/${audio._audio}`);
                        console.log(`音檔 ${audio._audio} 刪除成功`);
                      } catch (err) {
                        console.error(`刪除音檔失敗: ${audio._audio}`, err);
                      }
                    } else {
                      console.log(`音檔為 null，無需刪除`);
                    }
                  })
                );
              }
            })
          );
        } else {
          console.log(`未找到與 sectionId ${sectionId} 匹配的 section`);
        }
      }

      // 處理每一個頁面
      const updatedPages = await Promise.all(
        studentAnswerInit.sections[0].data.map((page) => processPage(page))
      );

      // 構建更新後的 studentAnswerInit
      const updatedStudentAnswerInit = {
        ...studentAnswerInit,
        sections: [
          {
            ...studentAnswerInit.sections[0],
            data: updatedPages
          }
        ]
      };

      //   console.log(updatedStudentAnswerInit, "更新後的 studentAnswerInit");

      // 發送更新的 API 請求
      const {
        data: { studentAnswer }
      } = await axios.patch(`studentAnswer`, {
        patch: { data: updatedStudentAnswerInit } // 傳遞處理過的資料
      });

      //   console.log(studentAnswer, "更新成功的 studentAnswer");

      // 如果有需要，可以 dispatch action 更新 Redux store
      dispatch({ type: UPDATE_STUDENTANSWER, payload: { studentAnswer } });

      if (callback) callback();
    } catch (error) {
      console.error("更新學生答案失敗", error);
    }
  };

// fetch current student studentAnswer
export const fetchStudentAnswer =
  (options, callback) => async (dispatch, getStore) => {
    const { booksetId, bookTypeId, bookId } = options;

    // 使用 URLSearchParams 來處理查詢參數
    const queryParams = new URLSearchParams();

    if (booksetId) queryParams.append("booksetId", booksetId);
    if (bookTypeId) queryParams.append("bookTypeId", bookTypeId);
    if (bookId) queryParams.append("bookId", bookId);

    // 構建查詢字串
    const query = queryParams.toString() ? `?${queryParams.toString()}` : "";

    try {
      // 發送請求到後端來獲取對應的資料
      const response = await axios.get(`studentAnswer${query}`);

      // 從 response.data 解構 studentAnswer
      const { studentAnswers } = response.data;

      // 假設你有一個 action type 來處理返回的數據
      dispatch({ type: FETCH_STUDENTANSWER, payload: studentAnswers });

      if (callback) callback();
    } catch (error) {
      console.error("Error fetching student answer:", error);
    }
  };

// fetch studentAnswer in this class
export const fetchClassroomStudentAnswers =
  (options, callback) => async (dispatch, getStore) => {
    const { classroomId, booksetId, bookTypeId, bookId } = options;

    // 使用 URLSearchParams 來處理查詢參數
    const queryParams = new URLSearchParams();

    if (booksetId) queryParams.append("booksetId", booksetId);
    if (bookTypeId) queryParams.append("bookTypeId", bookTypeId);
    if (bookId) queryParams.append("bookId", bookId);

    // 構建查詢字串
    const query = queryParams.toString() ? `?${queryParams.toString()}` : "";

    try {
      // 發送請求到後端來獲取對應的資料;
      const response = await axios.get(
        `classroom/${classroomId}/studentAnswers${query}`
      );
      // 從 response.data 解構 studentAnswer
      const { studentAnswers } = response.data;
      // 假設你有一個 action type 來處理返回的數據
      dispatch({ type: FETCH_CLASSROOMSTUDENTANSWER, payload: studentAnswers });
      if (callback) callback();
    } catch (error) {
      console.error("Error fetching student answer:", error);
    }
  };
